<template>
  <Modal :class="{'is-active': active }">
    <Box customClass="is-shadowless">
      <Section>
        <h4 class="title is-4">Create a milestone</h4>
        <div class="form">

          <fieldset :disabled="isprogress">

            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input class="input" type="text" placeholder="Name of Milestone" v-model="vname">
              </div>
            </div>

            <div class="field">
              <label class="label">Description</label>
              <div class="control">
                <textarea class="textarea" placeholder="Name of project" v-model="vdesc"></textarea>
              </div>
            </div>

            <div class="field is-grouped">

                <div class="field mr-4">
                  <label class="label">Start Date</label>
                  <div class="control">
                    <!-- <input class="input" type="text" placeholder="Name of Milestone" v-model="sdate"> -->
                    <datepicker v-model="sdate" format="YYYY-MM-DD" class="input mr-2" :lowerLimit="new Date()"  />
                  </div>
                </div>


                <div class="field">
                  <label class="label">Due Date</label>
                  <div class="control">
                    <datepicker v-model="edate" format="YYYY-MM-DD" class="input" :lowerLimit="sdate"  />
                  </div>
                </div>

            </div>

            <div class="field is-grouped">
              <div class="control">
                <button class="button is-link" @click.prevent="onCreateHandle">Submit</button>
              </div>
              <div class="control">
                <button class="button is-link is-light" @click.prevent="toggleMilestoneModal">Cancel</button>
              </div>
            </div>
          </fieldset>
        </div>
      </Section>
      <Section v-if="error">
          {{ error }}
      </Section>
    </Box>
  </Modal>
</template>

<script>
  import {defineAsyncComponent, ref } from 'vue'
  import moment from 'moment'
  import Datepicker from 'vue3-datepicker'
  import { createMilestone } from '@/use/useMilestones'


  export default {
    components: {
      Modal: defineAsyncComponent(() => import('@/components/ds/Modal')),
      Box: defineAsyncComponent(() => import('@/components/ds/Box')),
      Section: defineAsyncComponent(() => import('@/components/ds/Section')),
      Datepicker
    },
    props: {
      active: {
        type: Boolean,
        default: false
      },
    },

    setup(props, context) {

      let vname = ref('')
      let vdesc = ref('')
      let sdate = ref(new Date())
      let edate = ref(new Date())
      let isprogress = ref(false)
      let error = ref('')


      const onCreateHandle = async () => {

        if (vname.value !== '') {

          if (!moment(sdate.value).isSame(edate.value)) {
              isprogress.value = true
              let payload = Object.assign({}, { title: vname.value, description: vdesc.value, due_date: edate.value, start_date: sdate.value })
              let res = await createMilestone(payload, 26)
              if (res.status === 201) {
                toggleMilestoneModal()
              }
          } else {
            error.value = "Please choose another Due Date"
          }


        }
      }

      const reset = () => {
        vname.value = ''
        vdesc.value = ''
        isprogress.value = false
      }

      const toggleMilestoneModal = () => {
        reset()
        context.emit("closeCreateMilestoneModal")
      }


      return {
        vname,
        vdesc,
        sdate,
        edate,
        error,
        isprogress,
        onCreateHandle,
        toggleMilestoneModal
      }
    }

  }
</script>

<style lang="scss" scoped>

</style>